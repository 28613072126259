html {
  scroll-snap-type: y mandatory;
}
.app {
  height: 100vh;
  background-color: black;
  display: grid;
  place-items: center;
}

.app__videos {
  position: relative;
  width: 80%;
  height: 800px;
  border-radius: 20px;
  overflow: scroll;
  max-width: 500px;
  scroll-snap-type: y mandatory;
}
.app__videos::-webkit-scrollbar {
  display: none;
}

.videoFooter {
  position: relative;
  color: white;
  bottom: 150px;
  margin-left: 40px;
  display: flex;
  opacity: 0.6;
}

.videoFooter__text > p {
  padding-bottom: 20px;
}
.videoFooter__text > h3 {
  padding-bottom: 20px;
}
.videoFooter__text {
  width: 100%;
}
.videoFooter__ticker > .ticker {
  height: fit-content;
  margin-left: 30px;
  width: 60%;
}
.videoFooter__icon {
  position: absolute;
}

.videoFooter__ticker h1 {
  padding-top: 7px;
}

.videoFooter__record {
  animation: spinTheRecord infinite 3s linear;
  height: 50px;
  width: 50px;
  position: absolute;
  right: 20px;
}
@keyframes spinTheRecord {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
